<template>
    <div class="m-banner">
        <img src="@/assets/banner/Slice1.png" alt="">
        <img src="@/assets/banner/Slice2.png" alt="">
        <img src="@/assets/banner/Slice3.png" alt="">
        <img src="@/assets/banner/Slice4.png" alt="">
        <img src="@/assets/banner/Slice5.png" alt="">
        <img src="@/assets/banner/Slice6.png" alt="">
        <img src="@/assets/banner/Slice7.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
    .m-banner{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        font-size: 0;
        img{
            width: 100%;
        }
    }
</style>